.App {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#hero {
  background-image: url(./Assets/img/hero-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
#hero h1 {
  font-size: 60px;
}
.navbar {
  transition: background-color 0.3s ease;
}

.bg-transparent {
  background: rgba(238, 32, 84, 0.33);
}

.scrolled {
  background-color: #FF4377 !important;
}
.navbar-logo {
  width: 120px;
}
.social-link {
  width: 35px;
}
